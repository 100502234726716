.dateInputContainer {
    display: flex;
}

.dateInput {
    flex-basis: 200px;
}

.dateInputBtn {
    margin-top: 40px;
}


.datePickerControls>button,
select {
    background-color: inherit;
    border: none !important;
    padding: 5px 10px;
    margin: 2px;
}

.datePickerControls>button:hover,
select:hover {
    background-color: rgb(223, 220, 220);
}

.datePickerControls>select {
    text-align: center;
    cursor: pointer;
    appearance: none;

}