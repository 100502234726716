:root {
  --header-bar-size: 63px;
}

.modal-button {
  margin-right: 10px;
}

.jumbotron {
  margin: 0;
  border-radius: .3rem .3rem 0 0;
  margin: 0 15px;
  padding: 20px 0
}

.rita-table-container {
  margin: 15px 15px;
}

.trade-details-caption {
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
  padding: 5px 0;
  margin-bottom: 5px;
  font-weight: 700;
  border-bottom: 3px solid #dee2e6;
}

.trade-details-caption_active {
  color: #e87e04;
}
