
/* Table Styles */

/* to do: move table styles inside table folder for moving table into ui lib in the future */

.table-wrapper {
  position: relative;
  overflow: auto;
  background: #3b454b;
  margin: 0 auto;
  display: block !important;
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td,
.fl-table th {
  text-align: left;
  padding: 8px;
}

.fl-table td {
  /* border-right: 1px solid #f8f8f8; */
  font-size: 12px;
  text-align: left;
}

.fl-table thead th {
  color: #ffffff;
  background: #142e3d;
  padding-top: 15px;
  padding-bottom: 15px;
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #142e3d;
}

.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

.fl-table tr.available:nth-child(even) {
  background: #bdddf4;
}

.fl-table tr.available:nth-child(odd) {
  background: #cde3f2;
}

.not-number {
  text-align: left !important;
}

.Number {
  text-align: right !important;
}
/* Responsive */

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }

  .table-wrapper:before {
    content: 'Scroll horizontally >';
    display: block;
    text-align: left;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }

  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }

  .fl-table thead th:last-child {
    border-bottom: none;
  }

  .fl-table thead {
    float: left;
  }

  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }

  .fl-table td,
  .fl-table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }

  .fl-table tbody tr {
    display: table-cell;
  }

  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }

  .fl-table tr:nth-child(even) {
    background: transparent;
  }

  .fl-table tr td:nth-child(odd) {
    background: #f8f8f8;
    border-right: 1px solid #e6e4e4;
  }

  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #e6e4e4;
  }

  .fl-table tbody td {
    display: block;
    text-align: center;
  }
}
